import styled from 'styled-components';
import { spacing } from '@ataden/design-system';

type DiskUsageStatusComponentProps = {
  color: string;
};

export const DiskUsageStatusComponent = styled.div<DiskUsageStatusComponentProps>`
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${(props) => props.color};
    margin-right: ${spacing(4)};
`;
