import React from 'react';
import { Server } from '../../../servers/domain/Server';
import styled from 'styled-components';
import { spacing } from '@ataden/design-system';

type HostSelectorProps = {
  servers: Server[];
  selection?: Server;
  onChange: (server: Server | undefined) => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Select = styled.select`
    padding: ${spacing(3)} ${spacing(5)};
    border-radius: 10px;
    font-size: 14px;
`;

export const HostSelector = ({ servers, selection, onChange }: HostSelectorProps) => {

  const onSelectChange = (serverId: string) => {
    if (serverId === undefined) {
      onChange(undefined);
      return;
    }

    onChange(servers.find((server) => server.serverId === serverId));
  };

  return (
        <Container>
            <label>Host:</label>
            <Select onChange={(evt) => onSelectChange(evt.target.value)} value={selection?.serverId}>
                <option value={undefined}/>
                {servers.map((server) => <option key={server.serverId}
                                                 value={server.serverId}>{server.hostname}</option>)}
            </Select>
        </Container>
  );
};
