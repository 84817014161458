import styled from 'styled-components';
import { AtadenLogo, AtdnButton, Colors } from '@ataden/design-system';
import html2canvas from 'html2canvas';
import { useRef } from 'react';
 
type VisitCardProps = {
  name: string;
  surname: string;
  role: string;
  email: string;
  phoneNumber: string;
};

const Container = styled.div`
  width: 352px;
  height: 232px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 8px 24px;
  height: 50%;
  background-image: url("/blocks.jpg");
`;

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50%;
  padding: 8px 24px 24px 24px;
  background-color: #D4C9FFFF;
`;

const Person = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: start;
`;

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: end;
`;

const NameText = styled.div`
  color: ${Colors.Brown700};
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;

  text-align: start;
`;

const RoleText = styled.div`
  text-align: start;
  color: ${Colors.Brown900};

  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
`;

const PhoneNumberText = styled.div`
  text-align: end;
  color: ${Colors.Brown900};

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

const EmailText = styled.div`
  text-align: end;
  color: ${Colors.Brown900};

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

export const VisitCard = ({ name, surname, role, phoneNumber, email }: VisitCardProps) => {

  const cardRef = useRef<HTMLDivElement>(null);

  const onSave = () => {
    const cardContent = cardRef.current;
    if (!cardContent) {
      return;
    }

    html2canvas(cardContent, { scale: 20 }).then(function (canvas) {
      const imgUrl = canvas.toDataURL('image/png');

      const link = document.createElement('a');
      link.download = 'visit card';
      link.href = imgUrl;
      link.dataset.downloadurl = ['image/png', link.download, link.href].join(':');

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
        <>
            <Container ref={cardRef}>
                <Header>
                    <AtadenLogo whiteMode/>
                </Header>
                <Profile>
                    <Person>
                        <NameText>{name} {surname}</NameText>
                        <RoleText>{role}</RoleText>
                    </Person>
                    <Contact>
                        <PhoneNumberText>{phoneNumber}</PhoneNumberText>
                        <EmailText>{email}</EmailText>
                    </Contact>
                </Profile>
            </Container>
            <AtdnButton onClick={onSave}>Save visit card</AtdnButton>
        </>
  );
};
