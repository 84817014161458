import { Colors, spacing } from '@ataden/design-system';
import { LogLevel } from '../../domain/Log';
import styled from 'styled-components';

type LogLevelComponentProps = {
  level: LogLevel;
};

type ContainerProps = {
  backgroundColor: string;
  borderColor: string;
};

const Container = styled.div<ContainerProps>`
    display: flex;
    padding: ${spacing(3)};
    border-radius: 10px;
    background-color: ${(props) => props.backgroundColor};
    border: 1px solid ${(props) => props.borderColor};
    justify-content: center;
    align-items: center;
    height: auto;
    width: 0px;
`;


export const LogLevelComponent = ({ level }: LogLevelComponentProps) => {

  if (level === 'ERROR') {
    return (
            <Container backgroundColor={Colors.Red100} borderColor={Colors.Red900}/>
    );
  }

  if (level === 'WARN') {
    return (
            <Container backgroundColor={Colors.Yellow100} borderColor={Colors.Yellow900}/>
    );
  }

  if (level === 'INFO') {
    return (
            <Container backgroundColor={Colors.Green100} borderColor={Colors.Green700}/>
    );
  }

  if (level === 'DEBUG') {
    return (
            <Container backgroundColor={Colors.Gray100} borderColor={Colors.Gray900}/>
    );
  }

  return <div/>;
};
