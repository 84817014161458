import { Package } from '../domain/Package';

type PackageVersionComboProps = {
  packages: Package[];
  currentPackage: Package | undefined;
  onPackageChange: (value: Package | undefined) => void;
};
 
export const PackageVersionSelector = ({ packages, currentPackage, onPackageChange }: PackageVersionComboProps) => {

  const onSelection = (value: string) => {
    onPackageChange(packages.find((p) => p.version === value));
  };

  return (
        <select value={currentPackage?.version} onChange={(evt) => onSelection(evt.target.value)}>
            {packages.map(((p) => <option key={p.version} value={p.version}>{p.version}</option>))}

        </select>
  );
};
