import { useEffect, useState } from 'react';
import { useGetServerDiskUsages } from '../repositories/ServerRepository';
import { DiskUsage } from '../domain/DiskUsage';
import { ServerDiskUsage } from './ServerDiskUsage';
import styled from 'styled-components';

type ServerDiskUsagesProps = {
  serverId: string;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const ServerDiskUsages = ({ serverId }: ServerDiskUsagesProps) => {

  const getServerDiskUsages = useGetServerDiskUsages();
  const [diskUsages, updateDiskUsage] = useState<DiskUsage[]>([]);

  useEffect(() => {
    getServerDiskUsages(serverId)
      .then((diskUsage) => {
        updateDiskUsage(diskUsage.sort((a, b) => a.mount.localeCompare(b.mount)));
      });
  }, [serverId]);

  return (
        <Container>
            {diskUsages.map(diskUsage => (
                <ServerDiskUsage key={diskUsage.mount} diskUsage={diskUsage}/>
            ))}
        </Container>
  );
};

