const monthLabel = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

const numberToString = (num: number, digitCount: number): string => {
  const result = num.toString().split('.')[0];
  if (result.length < digitCount) {
    return '0'.repeat(digitCount - result.length) + result;
  }

  return result;
};

export const dateNumberToDateString = (date: number, dateFormat: string): string => {
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();

  let currentDate = dateFormat.replace('DD', numberToString(day, 2));
  currentDate = currentDate.replace('MM', numberToString(month + 1, 2));
  return currentDate.replace('YYYY', numberToString(year, 4));
};

export const dateStringToDateNumber = (dateStr: string, dateFormat: string): number => {
  const dateParts = dateFormat.split('/');
  const dateItems = dateStr.split('/');

  let day = 0;
  let month = 0;
  let year = 0;

  dateParts.forEach((datePart, index) => {
    if (datePart === 'DD') {
      day = parseInt(dateItems[index]);
      return;
    }
    if (datePart === 'MM') {
      month = parseInt(dateItems[index]) - 1;
      return;
    }
    if (datePart === 'YYYY') {
      year = parseInt(dateItems[index]);
      return;
    }
  });

  return new Date(year, month, day).getTime();
};

export const dateNumberToShortDateString = (date: number): string => {
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.getMonth();

  return monthLabel[month] + ' ' + numberToString(day, 2);
};

export const timeNumberToTimeString = (time: number, timeFormat: string): string => {
  const dateObj = new Date(time);
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const seconds = dateObj.getSeconds();
  const milli = (time % 1000000) / 1000;
  const micro = time % 1000;

  let currentTime = timeFormat.replace('hh', numberToString(hours, 2));
  currentTime = currentTime.replace('mmm', numberToString(milli, 3));
  currentTime = currentTime.replace('uuu', numberToString(micro, 3));
  currentTime = currentTime.replace('mm', numberToString(minutes, 2));
  currentTime = currentTime.replace('ss', numberToString(seconds, 2));
  return currentTime;
};

export const timeStringToTimeNumber = (timeStr: string, timeFormat: string): number => {
  const timeParts = timeFormat.split(':');
  const timeItems = timeStr.split(':');

  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  timeParts.forEach((timePart, index) => {
    if (timePart === 'hh') {
      hours = parseInt(timeItems[index]);
      return;
    }
    if (timePart === 'mm') {
      minutes = parseInt(timeItems[index]);
      return;
    }
    if (timePart === 'ss') {
      seconds = parseInt(timeItems[index]);
      return;
    }
  });

  return new Date(0, 0, 0, hours, minutes, seconds).getTime();
};
