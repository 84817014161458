import React, { useEffect, useState } from 'react';
import { useGetServers } from '../repositories/ServerRepository';
import { Server } from '../domain/Server';
import styled from 'styled-components';
import { AtdnText, Colors, spacing } from '@ataden/design-system';
import { ServerComponent } from '../components/ServerComponent';
import { ServerPanel } from '../components/ServerPanel';
import { ServerRegistrationButton } from '../components/ServerRegistrationButton';

const Container = styled.div`
    padding: ${spacing(5)};
`;

const ListContainer = styled.div`

    margin-top: ${spacing(9)};
`;

const ServerHeaderContainer = styled.div`
    display: grid;
    grid-template-columns: 100px minmax(0px, 1fr) 150px 150px 350px;
    column-gap: 16px;

    width: 100%;
    padding: ${spacing(5)};
    margin-bottom: ${spacing(4)};
    border-bottom: 1px solid ${Colors.Gray100};
`;

export const ServerPage = () => {
  const [servers, updateServers] = useState<Server[]>([]);
  const [selectedServer, updateSelectedServer] = useState<Server | undefined>();

  const getServers = useGetServers();

  useEffect(() => {
    getServers().then(updateServers);
  }, [getServers]);

  const selectServer = (server: Server) => {
    if (selectedServer === server) {
      updateSelectedServer(undefined);
      return;
    }

    updateSelectedServer(server);
  };

  const onServerCreated = () => {
    getServers().then(updateServers);
  };

  return (
        <Container>
            <AtdnText>Servers</AtdnText>

            <ListContainer>
                <ServerHeaderContainer>
                    <div/>
                    <div>hostname</div>
                    <div>datacenter</div>
                    <div>IP</div>
                    <div>id</div>
                </ServerHeaderContainer>
                {servers.map(server => <ServerComponent key={server.serverId} server={server}
                                                        onSelection={() => selectServer(server)}/>)}
            </ListContainer>

            {selectedServer && (
                <ServerPanel server={selectedServer} onClose={() => updateSelectedServer(undefined)}/>
            )}

            <ServerRegistrationButton onServerCreated={onServerCreated}/>

        </Container>
  );
};
