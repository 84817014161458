import styled from 'styled-components';
import { AtdnText, Colors, DownloadIcon, RefreshIcon, spacing } from '@ataden/design-system';
import React, { useEffect, useState } from 'react';
import { useDeployDomain, useGetSupportedDomains, useRenewDomain } from '../repositories/CertificateRepository';
import { DomainCreationInput } from '../components/DomainCreationInput';

const Container = styled.div`
    padding: ${spacing(5)};
`;

const ListContainer = styled.div`

    margin-top: ${spacing(9)};
    margin-bottom: 32px;
`;

const DomainContainer = styled.div`
    display: grid;
    grid-template-columns: minmax(0px, 1fr) 350px 100px;
    column-gap: 16px;

    width: 100%;
    padding: ${spacing(5)};
    margin-bottom: ${spacing(4)};
    border-bottom: 1px solid ${Colors.Gray100};
`;

const LineContainer = styled.div`
    display: grid;
    grid-template-columns: minmax(0px, 1fr) 350px 100px;

    width: 100%;
    margin-bottom: ${spacing(4)};
`;

const IconWrapper = styled.div`
    cursor: pointer;
`;

const IconContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

export const DomainPage = () => {
  const [domains, updateDomains] = useState<string[]>([]);

  const getDomains = useGetSupportedDomains();
  const deployDomain = useDeployDomain();
  const renewDomain = useRenewDomain();

  useEffect(() => {
    getDomains().then(updateDomains);
  }, [getDomains]);

  const onDomainCreated = () => {
    getDomains().then(updateDomains);
  };

  const onRenewDomain = (domain: string) => {
    renewDomain(domain);
  };

  const onDeployDomain = (domain: string) => {
    deployDomain(domain);
  };

  return (
        <Container>
            <AtdnText>Domains</AtdnText>

            <ListContainer>
                <DomainContainer>
                    <div>domain</div>
                    <div>creation date</div>
                    <div/>
                </DomainContainer>
                {domains.map((domain, idx) => (
                    <LineContainer key={'_' + idx}>
                        <div>{domain}</div>
                        <div/>
                        <IconContainer>
                            <IconWrapper onClick={() => onRenewDomain(domain)}><RefreshIcon
                                width="24px"/></IconWrapper>
                            <IconWrapper onClick={() => onDeployDomain(domain)}><DownloadIcon
                                width="24px"/></IconWrapper>
                        </IconContainer>
                    </LineContainer>
                ))}
            </ListContainer>

            <DomainCreationInput onDomainCreated={onDomainCreated}/>

        </Container>
  );
};
