import styled from 'styled-components';
import { AtdnButton, AtdnText } from '@ataden/design-system';
import { useRef } from 'react';
import html2canvas from 'html2canvas';

type ServerCableStickerProps = {
  serverName: string;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;

const StickerOuterContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding: 5px;
`;

const StickerContainer = styled.div`
    padding: 4px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 350px; // 5.2 cm
    height: 45px; // 2 cm

`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 4px;
    gap: 8px;
`;

export const ServerCableSticker = ({ serverName }: ServerCableStickerProps) => {
  const stickerRef = useRef(null);

  const onCreate = () => {

    html2canvas(stickerRef.current!)
      .then(canvas => {
        const type = 'image/png';
        canvas.toBlob((blob) => {
          const data = [new ClipboardItem({ [type]: blob! })];
          navigator.clipboard.write(data);
        }, type);
      });
  };

  return (
        <Container>
            <StickerOuterContainer>
                <StickerContainer ref={stickerRef}>
                    <Description>
                        <AtdnText scale={6}>{serverName}</AtdnText>
                    </Description>
                </StickerContainer>
                <AtdnButton onClick={onCreate}>Copy</AtdnButton>
            </StickerOuterContainer>
        </Container>
  );
};
