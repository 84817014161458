import { Business } from '../../domain/Business';
import styled from 'styled-components';
import { Colors, spacing } from '@ataden/design-system';
import { dateNumberToDateString, timeNumberToTimeString } from '../../../common/DateUtils';

type BusinessLineProps = {
  business: Business;

  onClick: () => void;
};

const BusinessContainer = styled.div`
    display: grid;
    grid-template-columns: 100px minmax(0px, 1fr) 300px 25px;
    column-gap: 16px;

    width: 100%;
    padding: ${spacing(5)};
    margin-bottom: ${spacing(4)};
    border-bottom: 1px solid ${Colors.Gray100};

    cursor: pointer;
`;

export const BusinessLine = ({ business, onClick }: BusinessLineProps) => {
  const date = dateNumberToDateString(business.createdDate, 'DD-MM-YYYY');
  const time = timeNumberToTimeString(business.createdDate, 'hh:mm:ss.mmm');


  return (<BusinessContainer onClick={onClick}>
        <div/>
        <div>{business.name}</div>
        <div>{date} - {time}</div>
    </BusinessContainer>);
};
