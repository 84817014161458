import { useAuthenticatedGet, useAuthenticatedPost } from '../../common/AuthenticatedRestCall.hook';
import { atadenAxiosInstance, Page, RestPage } from '../../common';
import { useCallback } from 'react';
import { Business } from '../domain/Business';

type RestBusiness = {
  id: string;
  name: string;
  createdDate: number;
  spaceQuotaLimit: number;
};

const restBusinessConverter = (businesses: RestPage<RestBusiness>): Page<Business> => {
  return { ...businesses };
};

export const useGetBusinesses = () => {
  const authenticatedGet = useAuthenticatedGet<RestPage<RestBusiness>, Page<Business>>(atadenAxiosInstance, restBusinessConverter);
  return useCallback((pageSize: number, pageNumber: number) => authenticatedGet('/backoffice/api/v1/businesses?pageSize=' + pageSize + '&pageNumber=' + pageNumber), [authenticatedGet]);
};

export const useUpdateBusinessStorageQuota = () => {
  const authenticatedPost = useAuthenticatedPost<void, void>(atadenAxiosInstance, () => {
  });
  return (businessId: string, storageQuotaLimit: number) => {
    return authenticatedPost('/backoffice/api/v1/businesses/' + businessId + '/storage-quota', { spaceQuotaLimit: storageQuotaLimit });
  };
};
