import { AtdnButton, Colors } from '@ataden/design-system';
import { useRef } from 'react';
 
type SignatureProps = {
  name: string;
  surname: string;
  role: string;
  phoneNumber: string;
};

export const EmailSignature = ({ name, surname, role, phoneNumber }: SignatureProps) => {

  const tableRef = useRef<HTMLTableElement>(null);

  const onButtonClick = () => {
    const element = tableRef.current;
    if (!element) {
      return;
    }

    navigator.clipboard.writeText(element.outerHTML)
      .then(() => {
        // TODO: display a toast
      });
  };

  return (
        <>
            <table cellPadding="0" cellSpacing="0" ref={tableRef}>
                <tr>
                    <td style={{ paddingTop: '16px' }}>
                        <table cellPadding="0" cellSpacing="0">
                            <tr>
                                <td style={{ textAlign: 'center', paddingTop: '4px' }}>
                                    <img src="https://ataden.com/logo-192x150.png" height="72px" alt="company logo"/>
                                </td>
                                <td style={{ textAlign: 'left', paddingLeft: '8px' }}>
                                    <table cellPadding="0" cellSpacing="0" style={{ paddingRight: '8px' }}>
                                        <tr style={{
                                          color: Colors.Brown700,
                                          fontWeight: 700,
                                          fontSize: '18px',
                                          lineHeight: '30px',
                                        }}>
                                            <td>
                                                {name} {surname}
                                            </td>
                                        </tr>
                                        <tr style={{
                                          fontWeight: 500,
                                          color: Colors.Brown900,
                                          fontSize: '16px',
                                          lineHeight: '28px',
                                        }}>
                                            <td>
                                                <span
                                                    style={{
                                                      color: Colors.Brown700,
                                                      fontWeight: 700,
                                                    }}>Ataden</span> | {role}
                                            </td>
                                        </tr>
                                        <tr style={{
                                          fontWeight: 500,
                                          color: Colors.Brown900,
                                          fontSize: '16px',
                                          lineHeight: '28px',
                                        }}>
                                            <td>
                                                <span>
                                                {phoneNumber}
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
            <AtdnButton onClick={onButtonClick}>Copy email signature to Clipboard</AtdnButton>
        </>
  );
};
