import { LogLevel } from '../../domain/Log';
import React from 'react';
import styled from 'styled-components';
import { spacing } from '@ataden/design-system';

type LevelSelectorProps = {
  selection?: LogLevel;
  onChange: (level: LogLevel | undefined) => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Select = styled.select`
    padding: ${spacing(3)} ${spacing(5)};
    border-radius: 10px;
    font-size: 14px;
`;

export const LevelSelector = ({ selection, onChange }: LevelSelectorProps) => {

  const onSelectChange = (type: string) => {
    if (type === 'ERROR') {
      onChange('ERROR');
      return;
    }

    if (type === 'WARN') {
      onChange('WARN');
      return;
    }

    if (type === 'INFO') {
      onChange('INFO');
      return;
    }

    if (type === 'DEBUG') {
      onChange('DEBUG');
      return;
    }

    onChange(undefined);
  };

  return (
        <Container>
            <label>Level:</label>
            <Select onChange={(evt) => onSelectChange(evt.target.value)} value={selection}>
                <option value={undefined}/>
                <option value={'ERROR'}>ERROR</option>
                <option value={'WARN'}>WARNING</option>
                <option value={'INFO'}>INFO</option>
                <option value={'DEBUG'}>DEBUG</option>
            </Select>
        </Container>
  );
};
