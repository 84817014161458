import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import { UserPage } from './users/pages/UserPage';
import { DashboardLayout } from './layout/DashboardLayout';
import { LogsPage } from './logs/pages/LogsPage';
import { IdentityPage } from './identity/pages/IdentityPage';
import { CompanyIdentityPage } from './identity/pages/CompanyIdentityPage';
import { AuthenticatedLayout } from './layout/AuthenticatedLayout';
import { ServerPage } from './servers/pages/ServerPage';
import { EventPage } from './events/pages/EventPage';
import { BusinessListPage } from './businesses/pages/BusinessListPage';
import { DomainPage } from './certifcates/pages/DomainPage';

export const RoutePath = {
  login: '/login',

  businesses: '/businesses',
  users: '/users',
  events: '/events',
  logs: '/logs',

  identity: '/identity',
  companyIdentity: '/identity/company',

  domains: '/domains',
  servers: '/servers',
};

export const AuthenticatedRouter = () => {

  return (
        <Routes>
            <Route element={<AuthenticatedLayout/>}>
                <Route index element={<Navigate to={RoutePath.servers} replace/>}/>
                <Route element={<DashboardLayout/>}>
                    <Route path={RoutePath.businesses} element={<BusinessListPage/>}/>

                    <Route path={RoutePath.users} element={<UserPage/>}/>

                    <Route path={RoutePath.events} element={<EventPage/>}/>
                    <Route path={RoutePath.logs} element={<LogsPage/>}/>

                    <Route path={RoutePath.identity} element={<IdentityPage/>}/>
                    <Route path={RoutePath.companyIdentity} element={<CompanyIdentityPage/>}/>

                    <Route path={RoutePath.domains} element={<DomainPage/>}/>
                    <Route path={RoutePath.servers} element={<ServerPage/>}/>
                </Route>
            </Route>
        </Routes>
  );
};
