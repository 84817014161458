import styled from 'styled-components';
import { Colors } from '@ataden/design-system';
import { EventAggregate } from '../../domain/EventAggregate';

type EventAggregateComponentProps = {
  eventAggregate: EventAggregate;
  onClick: () => void;
};
 
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  cursor: pointer;
`;

const TypeLabel = styled.div`
  font-size: 14px;
`;

const DateLabel = styled.div`
  font-size: 12px;
  color: ${Colors.Gray500};
`;

export const EventAggregateComponent = ({ eventAggregate, onClick }: EventAggregateComponentProps) => {
  const date = new Date(eventAggregate.createdDate);

  const formattedDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  const formattedTime = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.' + date.getMilliseconds();

  return (
        <Container onClick={onClick}>
            <TypeLabel>{eventAggregate.type}</TypeLabel>
            <DateLabel>{formattedDate + ' ' + formattedTime}</DateLabel>
        </Container>
  );
};
