import { atadenAxiosInstance, convertRestPageToDomain, Page, RestPage } from '../../common';
import { EventAggregate } from '../domain/EventAggregate';
import { useAuthenticatedGet } from '../../common/AuthenticatedRestCall.hook';
 
type RestEventAggregate = {

  id: string;
  eventId: string;
  type: string;
  createdDate: number;
};

const convertRestEventAggregateToDomain = (restEventAggregate: RestEventAggregate): EventAggregate => {
  return {
    ...restEventAggregate,
  };
};

const convertRestPageEventAggregateToDomain = (rest: RestPage<RestEventAggregate>) => convertRestPageToDomain<RestEventAggregate, EventAggregate>(rest, convertRestEventAggregateToDomain);

export const useSearchAggregates = () => {
  const protectedGet = useAuthenticatedGet<RestPage<RestEventAggregate>, Page<EventAggregate>>(atadenAxiosInstance, convertRestPageEventAggregateToDomain);

  return (aggregateId: string): Promise<Page<EventAggregate>> => {
    return protectedGet('/backoffice/api/v1/events/search?aggregateId=' + aggregateId);
  };
};

export const useFetchEventPayload = () => {
  const protectedGet = useAuthenticatedGet<string, string>(atadenAxiosInstance, (data: string): string => data);

  return (eventId: string): Promise<string> => {
    return protectedGet('/backoffice/api/v1/events/' + eventId);
  };
};

