import { AtdnButton, AtdnInput, AtdnText } from '@ataden/design-system';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useRenewDomain } from '../repositories/CertificateRepository';

type DomainCreationInputProps = {
  onDomainCreated: (domain: string) => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 32px;
    max-width: 420px;
    width: 100%;
`;

export const DomainCreationInput = ({ onDomainCreated }: DomainCreationInputProps) => {
  const [domain, updateDomain] = useState<string>('');

  const renewCertificate = useRenewDomain();

  const onCreate = () => {
    if (domain.trim().length === 0) {
      return;
    }

    renewCertificate(domain);
    onDomainCreated(domain);
  };

  return (
        <>
            <AtdnText>Add a domain</AtdnText>
            <Container>
                <AtdnInput label="domain name" value={domain} onChange={(evt) => updateDomain(evt.target.value)}/>
                <AtdnButton onClick={onCreate}>Create</AtdnButton>
            </Container>
        </>
  );
};
