import styled from 'styled-components';

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    width: 100%;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    width: 100%;
`;

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const EditIconWrapper = styled.div`
    width: 24px;
    height: 24px;
    cursor: pointer;
`;
