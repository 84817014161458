import styled from 'styled-components';
import { Colors, spacing } from '@ataden/design-system';
import React from 'react';
import { Log } from '../../domain/Log';
import { LogItem } from './LogItem';

type LogsDisplayerProps = {
  logs: Log[];
};

const LogContainer = styled.div`
    display: grid;
    grid-template-columns: 15px 160px minmax(0px, 1fr) 100px 150px 50px;
    column-gap: 16px;

    width: 100%;
    padding: ${spacing(5)};
    margin-bottom: ${spacing(4)};
    border-bottom: 1px solid ${Colors.Gray100};
`;

export const LogsDisplayer = ({ logs }: LogsDisplayerProps) => {

  return (
        <>
            <LogContainer>
                <div/>
                <div>date</div>
                <div>message</div>
                <div>service</div>
                <div>host</div>
            </LogContainer>
            {logs.map((log) => <LogItem key={log.date + log.serverId} log={log}/>)}
        </>

  );
};
