export type PageIndex = {
  selectable: boolean;
  value: number;
};

export const buildSmallIndexes = (pageCount: number): PageIndex[] => {
  const results: PageIndex[] = [];
  let i;
  for (i = 0; i < pageCount; i++) {
    results.push({
      selectable: true,
      value: i + 1,
    });
  }
  return results;
};
 
export const buildPageIndexes = (pageCount: number, currentPage: number): PageIndex[] => {
  if (pageCount <= 11) {
    return buildSmallIndexes(pageCount);
  }

  const results: PageIndex[] = [];

  results.push({
    selectable: true,
    value: 1,
  });

  results.push({
    selectable: true,
    value: 2,
  });

  if (currentPage <= 6) {
    results.push({
      selectable: true,
      value: 3,
    });

    results.push({
      selectable: true,
      value: 4,
    });

    results.push({
      selectable: true,
      value: 5,
    });

    results.push({
      selectable: true,
      value: 6,
    });

    results.push({
      selectable: true,
      value: 7,
    });

    results.push({
      selectable: true,
      value: 8,
    });

    results.push({
      selectable: false,
      value: 0,
    });
  } else if (currentPage >= pageCount - 5) {
    results.push({
      selectable: false,
      value: 0,
    });

    results.push({
      selectable: true,
      value: pageCount - 7,
    });

    results.push({
      selectable: true,
      value: pageCount - 6,
    });

    results.push({
      selectable: true,
      value: pageCount - 5,
    });

    results.push({
      selectable: true,
      value: pageCount - 4,
    });

    results.push({
      selectable: true,
      value: pageCount - 3,
    });

    results.push({
      selectable: true,
      value: pageCount - 2,
    });

  } else {
    results.push({
      selectable: false,
      value: 0,
    });

    results.push({
      selectable: true,
      value: currentPage - 2,
    });

    results.push({
      selectable: true,
      value: currentPage - 1,
    });

    results.push({
      selectable: true,
      value: currentPage,
    });

    results.push({
      selectable: true,
      value: currentPage + 1,
    });

    results.push({
      selectable: true,
      value: currentPage + 2,
    });

    results.push({
      selectable: false,
      value: 0,
    });
  }

  results.push({
    selectable: true,
    value: pageCount - 1,
  });

  results.push({
    selectable: true,
    value: pageCount,
  });

  return results;
};
