import { ReactNode } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

type Auth0PreConfiguredProvider = {
  children: ReactNode;
};
 
export const AuthenticationProvider = ({ children }: Auth0PreConfiguredProvider) => {
  const domain = process.env.REACT_APP__AUTH__DOMAIN!;
  const clientId = process.env.REACT_APP__AUTH__CLIENT_ID!;
  const redirection = process.env.REACT_APP__AUTH__LOGIN_REDIRECT!;
  const audience = process.env.REACT_APP__AUTH__AUDIENCE!;

  return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
              audience: audience,
              redirect_uri: redirection,
            }}
        >
            {children}
        </Auth0Provider>
  );
};
