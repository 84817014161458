import React, { useEffect, useState } from 'react';
import { useGetBusinesses } from '../repositories/BusinessRepository';
import styled from 'styled-components';
import { AtdnText, Colors, spacing } from '@ataden/design-system';
import { Business } from '../domain/Business';
import { BusinessLine } from './components/BusinessLine';
import { BusinessPanel } from './components/BusinessPanel';

const BusinessHeaderContainer = styled.div`
    display: grid;
    grid-template-columns: 100px minmax(0px, 1fr) 300px 25px;
    column-gap: 16px;

    width: 100%;
    padding: ${spacing(5)};
    margin-bottom: ${spacing(4)};
    border-bottom: 1px solid ${Colors.Gray100};
`;

export const BusinessListPage = () => {

  const getBusinesses = useGetBusinesses();

  const [businesses, updateBusinesses] = useState<Business[]>([]);
  const [currentBusiness, updateCurrentBusiness] = useState<Business>();

  useEffect(() => {
    getBusinesses(200, 0)
      .then((page) => updateBusinesses(page.items));
  }, []);

  const onBusinessClick = (business: Business) => {
    if (business.id === currentBusiness?.id) {
      updateCurrentBusiness(undefined);
    } else {
      updateCurrentBusiness(business);
    }
  };

  const onUpdateCurrentBusiness = (business: Business) => {
    updateBusinesses(businesses.map((b) => {
      if (b.id === business.id) {
        return { ...business };
      }
      return { ...b };
    }));

    updateCurrentBusiness({ ...business });
  };

  return (<div>
        <BusinessHeaderContainer>
            <div/>
            <AtdnText type="important">name</AtdnText>
            <AtdnText type="important">creation date</AtdnText>
        </BusinessHeaderContainer>
        {businesses.map((business) => <BusinessLine key={business.id} business={business}
                                                    onClick={() => onBusinessClick(business)}/>)}
        {currentBusiness &&
            <BusinessPanel business={currentBusiness} onBusinessUpdated={onUpdateCurrentBusiness}
                           onClose={() => updateCurrentBusiness(undefined)}/>}
    </div>);
};
