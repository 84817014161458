import React from 'react';
import styled from 'styled-components';
import { AtadenLogo } from '@ataden/design-system';
import { CompanyFlyer } from '../components/CompanyFlyer';
 
const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: fit-content;
  gap: 32px;
  align-items: center;
`;

export const CompanyIdentityPage = () => {

  return (
        <Container>
            <AtadenLogo width="800px"/>
            <CompanyFlyer/>
        </Container>
  );
};
