import styled from 'styled-components';
import React, { useState } from 'react';
import { useFetchEventPayload, useSearchAggregates } from '../repositories/EventRepository.hook';
import { EventAggregate } from '../domain/EventAggregate';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { EventAggregateComponent } from './components/EventAggregateComponent';
 
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchPanel = styled.div`
  display: flex;
  flex-direction: row;
`;

const ResultSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const AggregatesPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const EventPayloadPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const EventPage = () => {

  const searchAggregates = useSearchAggregates();
  const fetchEventPayload = useFetchEventPayload();

  const [searchField, updateSearchField] = useState<string>('6acc9648-7153-4af5-8601-b76e4f3ee374');
  const [aggregates, updateAggregates] = useState<EventAggregate[]>([]);
  const [eventPayload, updateEventPayload] = useState<string | undefined>();

  const onSearch = () => {
    searchAggregates(searchField)
      .then((response) => {
        updateAggregates(response.items);
      })
      .catch((failure) => {
        console.log('failure', failure);
      });
  };

  const onAggregateClick = (aggregate: EventAggregate) => {
    fetchEventPayload(aggregate.eventId)
      .then((payload) => {
        updateEventPayload(JSON.stringify(payload, null, 2));
      }).catch((failure) => {
        console.log('failure', failure);
      });

  };

  return (
        <Container>
            <SearchPanel>
                <input value={searchField} onChange={(event) => updateSearchField(event.target.value)}/>
                <button onClick={onSearch}>Search</button>
            </SearchPanel>
            <ResultSection>
                <AggregatesPanel>
                    {aggregates.map((aggregate) => (
                        <EventAggregateComponent
                            key={aggregate.eventId}
                            eventAggregate={aggregate}
                            onClick={() => onAggregateClick(aggregate)}
                        />))}
                </AggregatesPanel>
                <EventPayloadPanel>
                    {eventPayload && (
                        <SyntaxHighlighter language="json" style={docco}>
                            {eventPayload}
                        </SyntaxHighlighter>
                    )}
                </EventPayloadPanel>
            </ResultSection>
        </Container>
  );
};
