import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { NavigationMenu } from '../common/menu/NavigationMenu';
 
const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

const Page = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  width: 100%;
  height: 100%;
`;

export const DashboardLayout = () => {
  return (
        <Container>
            <NavigationMenu/>
            <Page>
                <PageContent>
                    <Outlet/>
                </PageContent>
            </Page>
        </Container>
  );
};
