import { controllerAxiosInstance } from '../../common';
import { useAuthenticatedGet } from '../../common/AuthenticatedRestCall.hook';
import { useCallback } from 'react';
import { Service, ServiceStatus } from '../domain/Service';

type RestService = {
  serverId: string;
  type: string;
  status: ServiceStatus;
  version: string;
  lastStatusChange: number;
};


const restServiceConverter = (services: RestService[]) => {
  return services.map(s => {
    return { ...s };
  });
};

export const useGetServices = () => {
  const authenticatedGet = useAuthenticatedGet<RestService[], Service[]>(controllerAxiosInstance, restServiceConverter);
  return useCallback((serverId: string) => authenticatedGet('/backoffice/api/v1/topology/servers/' + serverId + '/apps'), [authenticatedGet]);
};
