import React, { useState } from 'react';
import { Log } from '../../domain/Log';
import styled from 'styled-components';
import { AtdnText, ChevronIcon, Colors, spacing } from '@ataden/design-system';
import { dateNumberToShortDateString, timeNumberToTimeString } from '../../../common/DateUtils';
import { LogLevelComponent } from './LogLevelComponent';

type LogItemProps = {
  log: Log;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${Colors.Gray100};
    padding: ${spacing(4)};
    margin-bottom: ${spacing(4)};
    width: 100%;
`;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: ${spacing(6)};
`;

const LogContainer = styled.div`
    display: grid;
    grid-template-columns: 160px  minmax(0px, 1fr) 100px 150px 50px;
    column-gap: 16px;

    width: 100%;
`;

const Message = styled(AtdnText)`
    line-break: anywhere;
`;


const IconWrapper = styled.div`
    max-width: 24px;
    max-height: 24px;
`;

const ExpensionContainer = styled.div`
    display: flex;
    flex-direction: column;

    padding-top: ${spacing(5)};
    margin-left: 115px;
`;

const Callstack = styled(AtdnText)``;

export const LogItem = ({ log }: LogItemProps) => {
  const [isExpanded, updateExpansion] = useState<boolean>(false);

  const dateMilli = log.date / 1000000;
  const time = dateNumberToShortDateString(dateMilli) + ' ' + timeNumberToTimeString(dateMilli, 'hh:mm:ss.mmm');

  const onExpandClick = () => {
    updateExpansion(!isExpanded);
  };

  return (
        <Container>
            <LogLevelComponent level={log.level}/>
            <Layout>
                <LogContainer>
                    <div>{time}</div>
                    <Message>{log.message}</Message>
                    <div>{log.type}</div>
                    <div>{log.hostname}</div>
                    {log.stackTrace
                      ? (<IconWrapper onClick={onExpandClick}>
                            <ChevronIcon direction={isExpanded ? 'BOTTOM' : 'RIGHT'}/>
                        </IconWrapper>)
                      : <div/>
                    }
                </LogContainer>
                {isExpanded && (<ExpensionContainer>
                    <Callstack>{log.stackTrace}</Callstack>
                </ExpensionContainer>)}
            </Layout>
        </Container>
  );
};
