import { usePrepareAgentInstall, useRegisterService } from '../repositories/ServerRepository';
import { AtdnButton, AtdnCopyButton } from '@ataden/design-system';
import React, { useState } from 'react';
import styled from 'styled-components';

type InstallAgentComponentProps = {
  serverId: string;
  agentRegistered: () => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const InstallAgentComponent = ({ serverId, agentRegistered }: InstallAgentComponentProps) => {
  const [installCmd, updateInstallCmd] = useState<string>('');

  const prepareAgentInstall = usePrepareAgentInstall();
  const registerService = useRegisterService();

  const onInstallCommandRequest = () => {
    prepareAgentInstall(serverId)
      .then(updateInstallCmd);
  };

  const onAgentRegistration = () => {
    registerService(serverId, 'AGENT')
      .then(agentRegistered);
  };

  if (installCmd.length > 0) {
    return (
            <Container>
                {installCmd}
                <AtdnCopyButton textToCopy={installCmd}
                                tooltipToCopy="Copy the curl command"
                                tooltipCopied="Command copied"/>
                <AtdnButton onClick={onAgentRegistration}>Register Agent</AtdnButton>
            </Container>
    );
  }

  return (
        <Container>
            <AtdnButton onClick={onInstallCommandRequest}>Install Agent</AtdnButton>
            <AtdnButton onClick={onAgentRegistration}>Register Agent</AtdnButton>
        </Container>
  );
};
