import { AtdnButton } from '@ataden/design-system';
import React, { useState } from 'react';
import { ServerRegistrationModal } from './ServerRegistrationModal';

type ServerRegistrationInputProps = {
  onServerCreated: (serverId: string) => void;
};

export const ServerRegistrationButton = ({ onServerCreated }: ServerRegistrationInputProps) => {
  const [modalVisibility, updateModalVisibility] = useState<boolean>(false);

  return (
        <>
            <AtdnButton onClick={() => updateModalVisibility(true)}>Register new server</AtdnButton>

            {modalVisibility && (
                <ServerRegistrationModal onServerCreated={onServerCreated}
                                         onClosed={() => updateModalVisibility(false)}/>
            )}
        </>
  );
};
