import styled from 'styled-components';
import { ServiceComponent } from './ServiceComponent';
import { AtdnText } from '@ataden/design-system';
import { Server } from '../domain/Server';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetServices } from '../repositories/ServiceRepository';
import { Service } from '../domain/Service';
import { ServiceRegistrationButton } from './ServiceRegistrationButton';
import { InstallAgentComponent } from './InstallAgentComponent';

type ServicesComponentProps = {
  server: Server;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const ServiceContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;


export const ServicesComponent = ({ server }: ServicesComponentProps) => {

  const getServices = useGetServices();

  const [services, updateServices] = useState<Service[]>([]);

  useEffect(() => {
    getServices(server.serverId)
      .then((result) => {
        const sortedResult = result.sort((s1, s2) => s1.type.localeCompare(s2.type));
        updateServices(sortedResult);
      });
  }, [server.serverId]);

  const serviceNames = useMemo(() => {
    return services.map((service) => service.type);
  }, [services]);

  const onServiceCreated = () => {
    getServices(server.serverId)
      .then(updateServices);
  };

  const hasNoAgent: boolean = services.find((service) => service.type === 'AGENT') === undefined;
  const canInstallAgent: boolean = services.length === 0 || hasNoAgent;

  return (
        <Container>
            <AtdnText type="important">services:</AtdnText>

            {services.length > 0 && (<ServiceContainer>
                {services.map((service) => (
                    <ServiceComponent key={service.serverId + '_' + service.type} serverId={server.serverId}
                                      service={service}/>
                ))}
                <ServiceRegistrationButton serverId={server.serverId} serviceNames={serviceNames}
                                           onServiceCreated={onServiceCreated}/>
            </ServiceContainer>)}

            {canInstallAgent && (
                <InstallAgentComponent serverId={server.serverId} agentRegistered={onServiceCreated}/>
            )}

        </Container>
  );
};
