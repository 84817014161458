import React, { useState } from 'react';
import styled from 'styled-components';
import { EmailSignature } from '../components/EmailSignature';
import { AtdnInput } from '@ataden/design-system';
import { VisitCard } from '../components/VisitCard';
 
const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 640px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 100%;
`;

const VisualContainer = styled.div`
  margin-bottom: 32px;
`;

export const IdentityPage = () => {

  const [name, updateName] = useState<string>('Stéphane');
  const [surname, updateSurname] = useState<string>('Lemaire');
  const [role, updateRole] = useState<string>('Président');
  const [phoneNumber, updatePhoneNumber] = useState<string>('+33 (0)7 50 36 95 67');
  const [email, updateEmail] = useState<string>('stephane@ataden.com');

  return (
        <Container>
            <InputContainer>
                <AtdnInput label="Name" value={name} onChange={(event) => updateName(event.target.value)}/>
            </InputContainer>
            <InputContainer>
                <AtdnInput label="Surname" value={surname} onChange={(event) => updateSurname(event.target.value)}/>
            </InputContainer>
            <InputContainer>
                <AtdnInput label="Role" value={role} onChange={(event) => updateRole(event.target.value)}/>
            </InputContainer>
            <InputContainer>
                <AtdnInput label="Phone Number" value={phoneNumber}
                           onChange={(event) => updatePhoneNumber(event.target.value)}/>
            </InputContainer>
            <InputContainer>
                <AtdnInput label="Email" value={email}
                           onChange={(event) => updateEmail(event.target.value)}/>
            </InputContainer>

            <VisualContainer>
                <EmailSignature name={name} surname={surname} role={role} phoneNumber={phoneNumber}/>
            </VisualContainer>
            <VisualContainer>
                <VisitCard name={name} surname={surname} role={role} phoneNumber={phoneNumber} email={email}/>
            </VisualContainer>
        </Container>
  );
};
