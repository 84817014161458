import { AtdnButton, AtdnInput, AtdnText, PenIcon } from '@ataden/design-system';
import { Business } from '../../domain/Business';
import { byteConversion } from '../../../common/ByteConvertion';
import { EditIconWrapper, InfoContainer, ItemContainer, LabelContainer } from './PanelItem.shared';
import { useState } from 'react';
import styled from 'styled-components';
import { useUpdateBusinessStorageQuota } from '../../repositories/BusinessRepository';

type StorageQuotaLimitProps = {
  business: Business;

  onBusinessUpdated: (business: Business) => void;
};

const InputLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ActionLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const StorageQuotaLimit = ({ business, onBusinessUpdated }: StorageQuotaLimitProps) => {
  const updateBusinessStorageQuota = useUpdateBusinessStorageQuota();

  const quotaLimit = byteConversion(business.spaceQuotaLimit);

  const originalValue = +(business.spaceQuotaLimit / (1024 * 1024 * 1024)).toFixed(2);

  const [editedQuotaLimit, updateQuotaLimit] = useState<number>(originalValue);
  const [editMode, updateEditMode] = useState<boolean>(false);

  const onEdit = () => {
    updateEditMode(true);
  };

  const onCancel = () => {
    updateQuotaLimit(originalValue);
    updateEditMode(false);
  };

  const onSave = () => {
    const updatedValue = editedQuotaLimit * 1024 * 1024 * 1024;
    updateBusinessStorageQuota(business.id, updatedValue)
      .then(() => {
        updateEditMode(false);

        onBusinessUpdated({ ...business, spaceQuotaLimit: updatedValue });
      })
      .catch(() => {
        // TODO: handle error
      });
  };

  return (
        <ItemContainer>
            <InfoContainer>
                <LabelContainer>
                    <AtdnText type="important">storage limit:</AtdnText>
                    {!editMode && <AtdnText>{quotaLimit}</AtdnText>}
                </LabelContainer>
                {!editMode && (
                    <EditIconWrapper onClick={onEdit}>
                        <PenIcon/>
                    </EditIconWrapper>
                )}
            </InfoContainer>
            {editMode && (
                <>
                    <InputLine>
                        <AtdnInput label="" value={'' + editedQuotaLimit}
                                   onChange={(evt) => updateQuotaLimit(+evt.target.value)}/>
                        <AtdnText>GB</AtdnText>
                    </InputLine>
                    <ActionLine>
                        <AtdnButton onClick={onCancel}>Cancel</AtdnButton>
                        <AtdnButton onClick={onSave}
                                    disabled={originalValue === editedQuotaLimit}>Update</AtdnButton>
                    </ActionLine>
                </>

            )}
        </ItemContainer>
  );
};
