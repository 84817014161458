import { AtdnButton, AtdnInput, AtdnModal, Colors, spacing } from '@ataden/design-system';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useRegisterServer } from '../repositories/ServerRepository';
import { Datacenter } from '../domain/Datacenter';

type ServerRegistrationInputProps = {
  onServerCreated: (serverId: string) => void;
  onClosed: () => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 32px;
    max-width: 420px;
    width: 100%;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 90%;
    color: ${Colors.Brown700};
    white-space: break-spaces;
    margin-bottom: 24px;
`;

const Select = styled.select`
    padding: ${spacing(3)} ${spacing(5)};
    border-radius: 10px;
    font-size: 14px;
`;

export const ServerRegistrationModal = ({ onClosed, onServerCreated }: ServerRegistrationInputProps) => {
  const [hostname, updateHostname] = useState<string>('');
  const [datacenter, updateDatacenter] = useState<string>('LYON_01');
  const [serverIp, updateServerIp] = useState<string>('');

  const registerServer = useRegisterServer();

  const onCreate = () => {
    if (hostname.trim().length === 0) {
      return;
    }

    if (datacenter.trim().length === 0) {
      return;
    }

    if (serverIp.trim().length === 0) {
      return;
    }
 
    registerServer(hostname, datacenter as Datacenter, serverIp)

      .then((serverId) => {
        onServerCreated(serverId);
        onClosed();
      });
  };

  const datacenters: string[] = useMemo(() => {
    return [
      'LYON_01',
      'HOME',
      'SCALEWAY_PAR1',
    ];
  }, []);

  return (
        <AtdnModal onCancel={onClosed}>
            <Title>
                Add a server
            </Title>
            <Container>
                <AtdnInput label="hostname" value={hostname} onChange={(evt) => updateHostname(evt.target.value)}/>
                <Select onChange={(evt) => updateDatacenter(evt.target.value)} value={datacenter}>
                    {datacenters.map((dc) => <option key={'_' + dc} value={dc}>{dc}</option>)}
                </Select>
                <AtdnInput label="serverIp" value={serverIp} onChange={(evt) => updateServerIp(evt.target.value)}/>
                <AtdnButton onClick={onCreate}>Create</AtdnButton>
            </Container>
        </AtdnModal>
  );
};
