import { LogRequest } from '../../domain/LogRequest';
import { LevelSelector } from './LevelSelector';
import { LogLevel, ServiceType } from '../../domain/Log';
import { ServiceTypeSelector } from './ServiceTypeSelector';
import { useGetServers } from '../../../servers/repositories/ServerRepository';
import { useEffect, useState } from 'react';
import { Server } from '../../../servers/domain/Server';
import { HostSelector } from './HostSelector';
import styled from 'styled-components';
import { spacing } from '@ataden/design-system';

type LogFilterProps = {
  request: LogRequest;
  onRequestChanged: (request: LogRequest) => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: ${spacing(5)};
`;

export const LogFilter = ({ request, onRequestChanged }: LogFilterProps) => {

  const [servers, updateServers] = useState<Server[]>([]);
  const [currentServerSelection, updateServerSelection] = useState<Server>();

  const getServers = useGetServers();

  useEffect(() => {
    getServers()
      .then(updateServers);
  }, []);

  const onLevelChanged = (level: LogLevel | undefined) => {
    onRequestChanged({
      ...request,
      level: level,
    });
  };

  const onTypeChanged = (type: ServiceType | undefined) => {
    onRequestChanged({
      ...request,
      type: type,
    });
  };

  const onServerChanged = (server: Server | undefined) => {
    onRequestChanged({
      ...request,
      serverId: server?.serverId,
    });

    updateServerSelection(server);
  };

  return (
        <Container>
            <LevelSelector selection={request.level} onChange={onLevelChanged}/>
            <ServiceTypeSelector selection={request.type} onChange={onTypeChanged}/>
            <HostSelector servers={servers} onChange={onServerChanged} selection={currentServerSelection}/>
        </Container>
  );
};
