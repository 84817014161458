import styled from 'styled-components';
import { Server } from '../domain/Server';
import { AtdnText, Colors, spacing } from '@ataden/design-system';
import React from 'react';
import { ServerStatusComponent } from './ServerStatusComponent';

type ServerComponentProps = {
  server: Server;

  onSelection: () => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;


const ServerContainer = styled.div`
  display: grid;
  grid-template-columns: 100px minmax(0px, 1fr) 150px 150px 350px;
  column-gap: 16px;

  width: 100%;
  padding: ${spacing(5)};
  margin-bottom: ${spacing(4)};
  border-bottom: 1px solid ${Colors.Gray100};
`;

export const ServerComponent = ({ server, onSelection }: ServerComponentProps) => {

  return (
        <Container>
            <ServerContainer onClick={onSelection}>
                <ServerStatusComponent status={server.status}/>
                <AtdnText>{server.hostname}</AtdnText>
                <div>{server.datacenter}</div>
                <div>{server.ip}</div>
                <div>{server.serverId}</div>
            </ServerContainer>
        </Container>
  );
};
