export const byteConversion = (bytes: number): string => {

  if (bytes < 1024) {
    return bytes.toFixed(2) + ' B';
  }

  if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + ' KB';
  }

  if (bytes < 1024 * 1024 * 1024) {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  }

  if (bytes < 1024 * 1024 * 1024 * 1024) {
    return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
  }

  if (bytes < 1024 * 1024 * 1024 * 1024 * 1024) {
    return (bytes / (1024 * 1024 * 1024 * 1024)).toFixed(2) + ' TB';
  }

  return (bytes / (1024 * 1024 * 1024 * 1024 * 1024)).toFixed(2) + ' PB';
};
