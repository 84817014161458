import { ServiceType } from '../../domain/Log';
import React from 'react';
import styled from 'styled-components';
import { spacing } from '@ataden/design-system';

type ServiceTypeSelectorProps = {
  selection?: ServiceType;
  onChange: (type: ServiceType | undefined) => void;
};

const possibleSelection: ServiceType[] = ['AGENT', 'ATADEN', 'CHUNK_STORE', 'CONTROL', 'WEB_FRONTAL', 'WEB_HOMEPAGE', 'WEB_CONSOLE', 'WEB_BACKOFFICE'];

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Select = styled.select`
    padding: ${spacing(3)} ${spacing(5)};
    border-radius: 10px;
    font-size: 14px;
`;

export const ServiceTypeSelector = ({ selection, onChange }: ServiceTypeSelectorProps) => {

  const onSelectChange = (type: string) => {
    if (type === undefined) {
      onChange(undefined);
      return;
    }

    const service = type as ServiceType;
    onChange(service);
  };

  return (
        <Container>
            <label>Service Type:</label>
            <Select onChange={(evt) => onSelectChange(evt.target.value)} value={selection}>
                <option value={undefined}/>
                {possibleSelection.map((type) => (
                    <option key={'_' + type} value={type}>{type}</option>
                ))}
            </Select>
        </Container>
  );
};
