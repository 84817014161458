import { DiskUsage } from '../domain/DiskUsage';
import styled from 'styled-components';
import { byteConversion } from '../../common/ByteConvertion';
import { DiskUsageStatusComponent } from './DiskUsageStatusComponent';
import { Colors } from '@ataden/design-system';

type ServerDiskUsageProps = {
  diskUsage: DiskUsage;
};

const Container = styled.div`
    display: grid;
    grid-template-columns: 15px minmax(0px, 1fr) 100px 100px;
    column-gap: 16px;
    max-width: 600px;
`;

const computeStatusColor = (value: number): string => {
  if (value < 75) {
    return Colors.Green700;
  } else if (value < 90) {
    return Colors.Yellow900;
  }
  return Colors.Red500;
};


export const ServerDiskUsage = ({ diskUsage }: ServerDiskUsageProps) => {

  const usedRatio = Math.ceil(diskUsage.used * 100 / diskUsage.capacity);
  const capacityStr = byteConversion(diskUsage.capacity);
  const statusColor = computeStatusColor(usedRatio);


  return (
        <Container>
            <DiskUsageStatusComponent color={statusColor}/>
            <div>
                {diskUsage.mount}
            </div>
            <div>
                {usedRatio}%
            </div>
            <div>
                {capacityStr}
            </div>
        </Container>
  );
};

