import { useAuthenticatedGet, useAuthenticatedPost } from '../../common/AuthenticatedRestCall.hook';
import { controllerAxiosInstance } from '../../common';
import { useCallback } from 'react';

type RestDomains = {
  domains: string[];
};


const restDomainsConverter = (domains: RestDomains): string[] => {
  return domains.domains;
};

export const useGetSupportedDomains = () => {
  const authenticatedGet = useAuthenticatedGet<RestDomains, string[]>(controllerAxiosInstance, restDomainsConverter);
  return useCallback(() => authenticatedGet('/backoffice/api/v1/certificates/domains'), [authenticatedGet]);
};

export const useRenewDomain = () => {
  const authenticatedPost = useAuthenticatedPost<void, void>(controllerAxiosInstance, () => {
  });
  return useCallback((domain: string) => authenticatedPost('/backoffice/api/v1/certificates/' + domain + '/renew', undefined), [authenticatedPost]);
};

export const useDeployDomain = () => {
  const authenticatedPost = useAuthenticatedPost<void, void>(controllerAxiosInstance, () => {
  });
  return useCallback((domain: string) => authenticatedPost('/backoffice/api/v1/certificates/' + domain + '/deploy', undefined), [authenticatedPost]);
};
