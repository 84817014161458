export type Page<T> = {
  pageSize: number;
  pageNumber: number;
  totalElements: number;
  items: T[];
};

export type RestPage<T> = {
  pageSize: number;
  pageNumber: number;
  totalElements: number;
  items: T[];
};

type ConvertRestPageToDomainType = <TRest, TDomain>(restPage: RestPage<TRest>, converter: (rest: TRest) => TDomain) => Page<TDomain>;

export const convertRestPageToDomain: ConvertRestPageToDomainType =
    <TRest, TDomain>(restPage: RestPage<TRest>, converter: (rest: TRest) => TDomain): Page<TDomain> => {
      return {
        pageSize: restPage.pageSize,
        pageNumber: restPage.pageNumber,
        totalElements: restPage.totalElements,
        items: restPage.items.map((item) => converter(item)),
      };
    };
