import { ServerStatus } from '../domain/Server';
import { Colors, spacing } from '@ataden/design-system';
import styled from 'styled-components';

export const serverStatusColor = (status: ServerStatus): string => {
  switch (status) {
    case 'OK':
      return Colors.Green700;
    case 'WARN':
      return Colors.Yellow900;
    case 'DOWN':
      return Colors.Red500;
  }
};

type StatusProps = {
  status: ServerStatus;
};

export const ServerStatusComponent = styled.div<StatusProps>`
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${(props) => serverStatusColor(props.status)};
    margin-right: ${spacing(4)};
`;
