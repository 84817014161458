import { AtdnButton, AtdnModal, Colors, spacing } from '@ataden/design-system';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useGetServicePackages, useInstallServicePackage, useRegisterService } from '../repositories/ServerRepository';
import { Package } from '../domain/Package';
import { PackageVersionSelector } from './PackageVersionSelector';

type ServiceRegistrationInputProps = {
  serverId: string;
  serviceNames: string[];
  onServiceCreated: () => void;
  onClosed: () => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 32px;
    max-width: 420px;
    width: 100%;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 90%;
    color: ${Colors.Brown700};
    white-space: break-spaces;
    margin-bottom: 24px;
`;

const Select = styled.select`
    padding: ${spacing(3)} ${spacing(5)};
    border-radius: 10px;
    font-size: 14px;
`;

const InstallContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const Separator = styled.div`
    display: flex;
    border-bottom: 1px solid ${Colors.Gray300};
    margin: 16px;
`;

const RegisterLabel = styled.div`
    display: flex;
    margin-top: 8px;
`;

export const ServiceRegistrationModal = ({
  serverId,
  serviceNames,
  onClosed,
  onServiceCreated,
}: ServiceRegistrationInputProps) => {
  const [type, updateType] = useState<string>('');
  const [packages, updatePackages] = useState<Package[]>([]);
  const [currentPackage, updateCurrentPackage] = useState<Package>();

  const registerService = useRegisterService();
  const getServicePackages = useGetServicePackages();
  const installServicePackage = useInstallServicePackage();

  const services: string[] = useMemo(() => {
    return [
      'AGENT',
      'ATADEN',
      'CHUNK_STORE',
      'CONTROL',
      'HAPROXY',
    ].filter((service) => serviceNames.find((name) => service === name) === undefined)
      .sort((a, b) => a.localeCompare(b));
  }, [serviceNames]);

  useEffect(() => {
    if (services.length === 0) {
      return;
    }

    updateType(services[0]);
  }, [services]);

  useEffect(() => {
    if (type === '') {
      return;
    }

    getServicePackages(type).then((result) => {
      updatePackages(result);

      if (result.length > 0) {
        updateCurrentPackage(result[0]);
      }
    });
  }, [type]);

  const onPackageChange = (value: Package | undefined) => {
    updateCurrentPackage(value);
  };

  const onSuccess = () => {
    onServiceCreated();
    onClosed();
  };
     
  const onInstallButton = () => {
    installServicePackage(serverId, type, currentPackage?.version!)
      .then(onSuccess);
  };


  const onCreate = () => {
    if (type.trim().length === 0) {
      return;
    }

    registerService(serverId, type)
      .then(onSuccess);
  };


  return (
        <AtdnModal onCancel={onClosed}>
            <Title>
                Add a service
            </Title>
            <Container>
                <Select onChange={(evt) => updateType(evt.target.value)} value={type}>
                    {services.map((service) => <option key={'_' + service} value={service}>{service}</option>)}
                </Select>
                <RegisterLabel>Register an existing service</RegisterLabel>
                <AtdnButton onClick={onCreate}>Register</AtdnButton>

                <Separator/>
                <div>Or install the service</div>
                {currentPackage && (
                    <InstallContainer>
                        <PackageVersionSelector packages={packages} currentPackage={currentPackage}
                                                onPackageChange={onPackageChange}/>

                        <AtdnButton onClick={onInstallButton}>Install</AtdnButton>
                    </InstallContainer>
                )}

            </Container>
        </AtdnModal>
  );
};
