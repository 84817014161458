import { Colors, spacing } from '@ataden/design-system';
import styled from 'styled-components';
import { ServiceStatus } from '../domain/Service';

export const serviceStatusColor = (status: ServiceStatus): string => {
  switch (status) {
    case 'READY':
      return Colors.Green700;
    case 'MAINTENANCE':
      return Colors.Yellow900;
    case 'DOWN':
      return Colors.Red500;
    case 'UNKNOWN':
      return Colors.Gray700;
  }
};

type StatusProps = {
  status: ServiceStatus;
};

export const ServiceStatusComponent = styled.div<StatusProps>`
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${(props) => serviceStatusColor(props.status)};
    margin-right: ${spacing(4)};
`;
