import { AtdnButton } from '@ataden/design-system';
import React, { useState } from 'react';
import { ServiceRegistrationModal } from './ServiceRegistrationModal';

type ServiceRegistrationInputProps = {
  serverId: string;
  serviceNames: string[];
  onServiceCreated: () => void;
};

export const ServiceRegistrationButton = ({
  serverId,
  serviceNames,
  onServiceCreated,
}: ServiceRegistrationInputProps) => {
  const [modalVisibility, updateModalVisibility] = useState<boolean>(false);

  return (
        <>
            <AtdnButton onClick={() => updateModalVisibility(true)}>Register new service</AtdnButton>

            {modalVisibility && (
                <ServiceRegistrationModal serverId={serverId} serviceNames={serviceNames}
                                          onServiceCreated={onServiceCreated}
                                          onClosed={() => updateModalVisibility(false)}/>
            )}
        </>
  );
};
