import styled from 'styled-components';
import { AtadenLogo, AtdnButton, AtdnText, Colors } from '@ataden/design-system';
import { QRCodeSVG } from 'qrcode.react';
import { useRef } from 'react';
import html2canvas from 'html2canvas';
 
const Container = styled.div`
  display: flex;
  position: relative;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 420px;
  height: 592px;

  padding: 32px;
  margin-bottom: 32px;

  background-color: #BFAEF9;

  background-image: url("/blocks.jpg");
  background-size: cover;
  background-position: center;
`;

const Logo = styled(AtadenLogo)`
  margin-bottom: 56px;
`;

const Mojo = styled(AtdnText)`
  text-align: center;
  margin-bottom: 36px;
  line-height: 45px;
`;

const QRCodeContainer = styled.div`
  padding: 8px;
  background-color: ${Colors.White};
  margin-bottom: 36px;
`;

const Footer = styled(AtdnText)`
  text-align: center;
`;

export const CompanyFlyer = () => {
  const flyerRef = useRef<HTMLDivElement>(null);

  const onSave = () => {
    const flyerContent = flyerRef.current;
    if (!flyerContent) {
      return;
    }

    html2canvas(flyerContent, { scale: 20 }).then(function (canvas) {
      const imgUrl = canvas.toDataURL('image/png');

      const link = document.createElement('a');
      link.download = 'flyer';
      link.href = imgUrl;
      link.dataset.downloadurl = ['image/png', link.download, link.href].join(':');

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
  // 54439DFF
  return (
        <div>
            <Container ref={flyerRef}>
                <Logo whiteMode height="56px"/>
                <Mojo color={Colors.White} scale={8}>Le Cloud Storage Eco-Performant</Mojo>
                <QRCodeContainer>
                    {/*<QRCodeSVG value="https://ataden.com/events" fgColor={Colors.Green700} size={150}/>*/}
                    <QRCodeSVG value="https://ataden.com/events" fgColor="#54439D" size={150}/>
                </QRCodeContainer>
                <Footer color={Colors.White} scale={6}>Bring cloud storage to the next stage.</Footer>
            </Container>
            <AtdnButton onClick={onSave}>Download flyer</AtdnButton>
        </div>
  );
};
