import { atadenAxiosInstance } from '../../common';
import { useAuthenticatedGet } from '../../common/AuthenticatedRestCall.hook';
import { useCallback } from 'react';
import { UserInfo } from '../domain/UserInfo';

type RestUserInfo = {
  id: string;
  role: number;
};


const restUserInfoConverter = (userInfo: RestUserInfo): UserInfo => {
  return { ...userInfo };
};

export const useGetCurrentUserInfo = () => {
  const authenticatedGet = useAuthenticatedGet<RestUserInfo, UserInfo>(atadenAxiosInstance, restUserInfoConverter);
  return useCallback(() => authenticatedGet('/protected/api/v1/users/me'), [authenticatedGet]);
};
