import { Server } from '../domain/Server';
import { AtdnText } from '@ataden/design-system';
import React from 'react';
import { InfoContainer, ItemContainer, LabelContainer } from '../../businesses/pages/components/PanelItem.shared';

type ServerInfoComponentProps = {
  server: Server;
};

const computeBastion = (datacenter: string): string => {
  if (datacenter === 'HOME') {
    return '192.168.1.11:22222';
  } else if (datacenter === 'SCALEWAY_PAR1') {
    return '212.47.234.123:61000';
  } else if (datacenter === 'LYON_01') {
    return '31.7.252.101:22222';
  }
  return '0.0.0.0';
};

export const ServerInfoComponent = ({ server }: ServerInfoComponentProps) => {

  const bastion = computeBastion(server.datacenter);

  return (
        <>
            <ItemContainer>
                <InfoContainer>
                    <LabelContainer>
                        <AtdnText type="important">id:</AtdnText>
                        <AtdnText>{server.serverId}</AtdnText>
                    </LabelContainer>
                </InfoContainer>
            </ItemContainer>
            <ItemContainer>
                <InfoContainer>
                    <LabelContainer>
                        <AtdnText type="important">sh:</AtdnText>
                        <AtdnText>ssh -J bastion@{bastion} ataden@{server.ip}</AtdnText>
                    </LabelContainer>
                </InfoContainer>
            </ItemContainer>
        </>
  );
};